import * as Sentry from '@sentry/vue';

export interface NuxtModuleSentryPlugin extends Record<string, unknown> {
  sentrySetContext: typeof Sentry.setContext;
  sentrySetUser: typeof Sentry.setUser;
  sentrySetTag: typeof Sentry.setTag;
  sentryAddBreadcrumb: typeof Sentry.addBreadcrumb;
}

// Adapted from https://github.com/nuxt-community/sentry-module/issues/358#issuecomment-1016983543
export default defineNuxtPlugin<NuxtModuleSentryPlugin>(() => {
  const { $router, vueApp } = useNuxtApp();

  const config = useRuntimeConfig();
  Sentry.init({
    app: vueApp,
    dsn: config.public.sentryDsn,
    integrations: process.client
      ? [
          // perform browser tracing only client-side
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation($router),
            tracingOrigins: ['localhost', 'fifteen.eu', /^\//],
          }),
        ]
      : [],
    logErrors: false,
    sampleRate: config.public.sentrySampleRate ?? 1.0,
    tracesSampleRate: config.public.sentryTracesSampleRate ?? 1.0,
    debug: config.public.sentryDebug ?? false,
    environment: config.public.sentryEnvironment ?? 'development',
    release: '1.45.0' || undefined,
    // The following enables exeptions to be logged to console despite logErrors being set to false
    // (preventing them from being passed to the default Vue err handler)
    beforeSend(event, hint) {
      // If it is an exception, log it.
      if (event.exception) {
        console.info('Exception catched by Sentry:');
        console.error(hint?.originalException);
      }
      // Do not send to Sentry in development
      if (process.env.NODE_ENV === 'development') return null;
      // Continue sending to Sentry
      return event;
    },
  });

  vueApp.mixin(
    Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update'],
    })
  );
  Sentry.attachErrorHandler(vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update'],
  });
  Sentry.setContext('runtime', {
    ssr: process.server,
    client: process.client,
  });

  return {
    provide: {
      sentrySetContext: (name, context) => Sentry.setContext(name, context),
      sentrySetUser: user => Sentry.setUser(user),
      sentrySetTag: (key, value) => Sentry.setTag(key, value),
      sentryAddBreadcrumb: breadcrumb => Sentry.addBreadcrumb(breadcrumb),
    },
  };
});
