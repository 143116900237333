import resetStorePlugin from '@/store/plugins/reset-store';

export default defineNuxtPlugin({
  name: 'store',
  async setup() {
    const { $pinia } = useNuxtApp();

    $pinia.use(resetStorePlugin);

    // Do not modify init order unless you know what you are doing
    await useGlobalStore($pinia).init();
    await useClientStore($pinia).initLocales();
    await useClientStore($pinia).init();
    const { clientState } = useClientStore();
    const { isPreview } = useGlobalStore();

    if (clientState.modules.showcase || isPreview) {
      await useShowcaseStore($pinia).init();
    }

    if (
      clientState.modules.profile ||
      clientState.modules.accountSettings ||
      isPreview
    ) {
      await useAuthStore($pinia).init();
      await useUserStore($pinia).init();
      // Profile store is also used in the account settings module (OTP mode and form fields)
      await useProfileStore($pinia).init();
    }
  },
});
