import { RentalStatus } from '@/fifteen-sdk/enums';
import { createCwError } from '@/lib/helpers/errors';
import { createFlowStep } from '@/lib/helpers/flow';
import { CwServerErrorCode } from '@/models/enums';

export const endRental = createFlowStep<RentalFlowPayload>(
  'endRental',
  async payload => {
    const api = useApi();

    const { data, error } = await api.post(
      `/user/rentals/${payload.rental.id}/end`
    );

    if (error.value) {
      throw createCwError(error.value.errorCode);
    }

    if (data.value?.rental.status !== RentalStatus.NeedsPayment) {
      return payload;
    }

    if (!data.value?.rental.payment.secret && !data.value?.rental.paymentId) {
      // The rental is still in needs_payment but the backend didn't return a secret or a payment id
      // This is most likely an issue for which the backend communication with the PSP failed
      throw createCwError(CwServerErrorCode.BackendUnhandledIssue);
    }

    return { payload, ...data.value };
  }
);

export const confirmRentalPayment = createFlowStep<RentalFlowPayload>(
  'confirmRentalPayment',
  async payload => {
    const { secret, is3DsRequired } = payload.rental.payment;

    if (
      payload.rental?.status !== RentalStatus.NeedsPayment ||
      !is3DsRequired
    ) {
      return payload;
    }

    const { confirmPayment } = usePayment();

    const { error } = await confirmPayment({
      secret,
      paymentId: payload.rental.paymentId,
      paymentMethodType: payload.rental.paymentMethodType,
    });

    if (error) {
      throw createCwError(error.code);
    }

    return payload;
  }
);

/**
 * Sync the rental status
 * Can be used e.g. after a regularization to make sure to get the new rental status
 */
export const syncRentalStatus = createFlowStep<RentalFlowPayload>(
  'syncRentalStatus',
  async payload => {
    if (payload.rental?.status !== RentalStatus.NeedsPayment) {
      return payload;
    }
    const api = useApi();

    const { error } = await api.post(`/user/rentals/${payload.rental.id}/end`);

    if (error.value) {
      throw createCwError(error.value?.errorCode);
    }

    return payload;
  }
);
