import revive_payload_client_4sVQNw7RlN from "/tmp/build/80754af9/customer-website/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/tmp/build/80754af9/customer-website/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/tmp/build/80754af9/customer-website/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/tmp/build/80754af9/customer-website/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/tmp/build/80754af9/customer-website/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/tmp/build/80754af9/customer-website/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/tmp/build/80754af9/customer-website/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/tmp/build/80754af9/customer-website/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/tmp/build/80754af9/customer-website/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/tmp/build/80754af9/customer-website/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import runtime_plugin_template_2318258a_2X6UWGiO1A from "/tmp/build/80754af9/customer-website/.nuxt/runtime.plugin.template.2318258a.ts";
import error_ldt3PQauZ0 from "/tmp/build/80754af9/customer-website/plugins/error.ts";
import fds_LLl6NquvxJ from "/tmp/build/80754af9/customer-website/plugins/fds.ts";
import locale_PT4e7V2ngC from "/tmp/build/80754af9/customer-website/plugins/locale.ts";
import routes_JiYzrRJOs9 from "/tmp/build/80754af9/customer-website/plugins/routes.ts";
import store_9xNuDHGAVU from "/tmp/build/80754af9/customer-website/plugins/store.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  runtime_plugin_template_2318258a_2X6UWGiO1A,
  error_ldt3PQauZ0,
  fds_LLl6NquvxJ,
  locale_PT4e7V2ngC,
  routes_JiYzrRJOs9,
  store_9xNuDHGAVU
]