import { joinURL } from 'ufo';

import { CwError, createCwError } from '@/lib/helpers/errors';
import { CwAppErrorCode } from '@/models/enums';
import { PaymentMethodType } from '@/fifteen-sdk/enums';

interface UsePaymentReturn {
  confirmPayment: (options: {
    /**
     * Secret is, used for Stripe 3DS
     */
    secret?: string;
    /**
     * Payment id, used for Monext 3DS (payment action)
     */
    paymentId?: string;
    /**
     * Payment method type
     */
    paymentMethodType?: PaymentMethodType;
  }) => Promise<{ error: CwError | null }>;
}

export function usePayment(): UsePaymentReturn {
  const { clientState } = useClientStore();
  const provider = clientState.paymentProvider.name;

  const route = useRoute();

  const currentPath = computed(() => route.path);

  async function confirmPayment(options: {
    paymentId?: string;
    secret?: string;
    paymentMethodType?: PaymentMethodType;
  }): Promise<{ error: CwError | null }> {
    switch (provider) {
      case 'stripe':
        const stripe = await useStripe();

        if (!options.secret) {
          return {
            error: createCwError(
              CwAppErrorCode.StripeCardPaymentConfirmationFailed
            ),
          };
        }

        const stripeResult = await (async () => {
          if (!options.secret) {
            return undefined;
          }

          switch (options.paymentMethodType) {
            case PaymentMethodType.Card:
            case PaymentMethodType.GooglePay:
            case PaymentMethodType.ApplePay:
              return await stripe.value?.confirmCardPayment(options.secret);
            case PaymentMethodType.Paypal:
              return await stripe.value?.confirmPayPalPayment(options.secret, {
                return_url: joinURL(
                  window.origin,
                  `${currentPath.value}?step=confirm-payment`
                ),
              });
          }
        })();

        if (!stripeResult || stripeResult.error) {
          return {
            error: createCwError(
              CwAppErrorCode.StripeCardPaymentConfirmationFailed
            ),
          };
        }
        break;

      case 'monext':
        const monext = useMonext();
        const monextResult = await monext.confirmPayment({
          paymentId: options.paymentId ?? '',
        });

        if (monextResult.error) {
          return {
            error: createCwError(
              CwAppErrorCode.StripeCardPaymentConfirmationFailed
            ),
          };
        }
        break;
      default:
        return {
          error: createCwError(CwAppErrorCode.UnsupportedPaymentProvider),
        };
    }

    return { error: null };
  }

  return {
    confirmPayment,
  };
}
