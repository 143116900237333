import { supportedLocalesConfig } from '@/config/locales';

/**
 * Plugin that init locale configuration
 */
export default defineNuxtPlugin({
  name: 'locale',
  dependsOn: ['store'],
  parallel: true,
  async setup() {
    const { user } = useUserStore();
    const { clientState } = useClientStore();

    const { init, locale } = useLocale();
    useLocaleFormat({
      locale,
      supportedLocalesConfig,
    });

    await init({
      locales: clientState.locales,
      defaultLocale: clientState.defaultLocale,
      ...(user?.locale && { locale: user?.locale as ISOLocale }),
    });
  },
});
