import { useHead } from '@unhead/vue';
import { keysOf } from '@fifteen/shared-lib';

import type { AxeptioCookiesChoices } from '@/types/axeptio';

const choices = ref<AxeptioCookiesChoices>({});
const isInit = ref(false);
const hasMadeChoice = ref(false);

const disabledTrackRoutes = ['embed'];
const disabledTrackRoutesRegex = new RegExp(
  `^(${disabledTrackRoutes.join('|')})`
);

/**
 * Handle user tracking consent + scripts injection
 */
export function useCookieConsent(): {
  choices: Ref<AxeptioCookiesChoices>;
  hasMadeChoice: Ref<boolean>;
} {
  const { locale } = useI18n();

  const config = useRuntimeConfig();

  const route = useRoute();

  watchImmediate(
    () => route.name,
    () => {
      const isRouteTrackingDisable = !disabledTrackRoutesRegex.test(
        route.name as string
      );

      if (!isRouteTrackingDisable) {
        return { choices, hasMadeChoice };
      }

      if (!isInit.value) {
        isInit.value = true;

        window.axeptioSettings = {
          clientId: config.public.userTrackingAxeptioClientId,
          cookiesVersion: `customer-website-${locale.value}`,
        };
        (window._axcb = window._axcb || []).push(axeptio => {
          axeptio.on(
            'cookies:complete',
            _choices => (choices.value = _choices)
          );
        });

        useHead({
          script: [
            {
              async: true,
              defer: true,
              src: `//static.axept.io/sdk-slim.js`,
            },
          ],
        });

        watch(locale, value => {
          window.axeptioSDK?.setCookiesVersion(`customer-website-${value}`);
        });

        watchOnce(choices, newChoices => {
          if (Object.keys(newChoices).length > 0) {
            hasMadeChoice.value = true;
          }
        });

        watch(choices, (newChoices, oldChoices) => {
          // Reload window if user has disabled tracking for a vendor
          if (
            keysOf(choices.value).some(
              key => oldChoices[key] && !newChoices[key]
            )
          ) {
            window.location.reload();
            return;
          }
        });
      }
    }
  );

  return { choices, hasMadeChoice };
}
