<template lang="pug">
FcwAlert
NuxtLayout
</template>

<style lang="stylus">
#__nuxt
  height 100vh

.slide-left-enter-active,
.slide-left-leave-active,
.slide-left-leave-to
  transition transform 300ms, opacity 300ms

.slide-left-leave-to
  opacity 0

.slide-left-enter-from
  opacity 0.4
  transform translateX(rem(-16))
</style>

<script setup lang="ts">
import { version } from '@/package.json';

useClientTheme();
const { locale } = useLocale();

useClientI18n();
await useZendeskWebWidget();

const { clientState } = useClientStore();

useClientSeoMeta(clientState.defaultSeo);

const { user, fetchUser } = useUserStore();

const api = useApi();
const authStore = useAuthStore();
const { isLoggedIn } = storeToRefs(authStore);

watchImmediate(locale, async () => {
  if (isLoggedIn.value && user?.locale !== locale.value) {
    await api.patch('/user', {
      body: {
        locale: locale.value,
      },
    });
    await fetchUser();
  }
});

const { choices } = useCookieConsent();

useUserTracking(choices);

// Leave this, we need to easily know which version is online.
// We could generate backwards traffic too :-)
if (process.client) {
  console.log(
    `CW v${version} - by%c Fifteen ✳`,
    `color: #ff0062; font-weight: bold`,
    'https://fifteen.eu'
  );
}
</script>
